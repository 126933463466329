/* app/dashboard/react_components/src/components/Layout/DashboardLayout.css */

.layout {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.content {
    flex: 1; /* Ensures content takes remaining space */
    padding: 20px;
}