/* app/dashboard/react_components/src/components/Dashboard/Dashboard.css */

.dashboard-container {
  padding: 20px;
  margin-top: 60px; /* Matches the height of the NavBar to prevent overlap */
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
  min-height: calc(100vh - 60px); /* Ensures the dashboard takes the full viewport height */
  box-sizing: border-box;
}

.dashboard-title {
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin-top: 0; /* Ensure no extra space above the title */
}

.filters {
  display: flex;
  gap: 15px;
  margin-bottom: 20px;
  align-items: center;
  justify-content: center;
}

.filters label {
  margin-right: 8px;
  font-weight: bold;
}

.filters input,
.filters select {
  padding: 5px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.search-button {
  padding: 5px 15px;
  font-size: 14px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.search-button:hover {
  background-color: #0056b3;
}

.dashboard-table {
  width: 100%;
  border-collapse: collapse;
  margin: 0 auto;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.dashboard-table th,
.dashboard-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.dashboard-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.dashboard-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.dashboard-table tr:hover {
  background-color: #f1f1f1;
}

.error {
  color: red;
  text-align: center;
  margin-top: 20px;
}