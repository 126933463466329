/* app/dashboard/react_components/src/components/Login/Login.css */

/* General styling */
body {
    font-family: 'Arial', sans-serif;
    margin: 0;
    padding: 0;
    background: #f3f4f6;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  /* Container for the login form */
  .login-container {
    background: #ffffff;
    padding: 30px 40px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
    text-align: center;
  }
  
  /* Title */
  .login-container h1 {
    font-size: 24px;
    font-weight: bold;
    color: #333333;
    margin-bottom: 20px;
  }
  
  /* Form styling */
  .login-container form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  /* Form group (label + input) */
  .login-container .form-group {
    text-align: left;
  }
  
  .login-container label {
    font-size: 14px;
    color: #555555;
    margin-bottom: 5px;
    display: inline-block;
  }
  
  .login-container input {
    width: 100%;
    padding: 10px;
    border: 1px solid #dddddd;
    border-radius: 4px;
    font-size: 14px;
    transition: border-color 0.3s;
  }
  
  .login-container input:focus {
    outline: none;
    border-color: #007BFF;
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
  }
  
  /* Button styling */
  .login-container button {
    background: #007BFF;
    color: #ffffff;
    font-size: 14px;
    font-weight: bold;
    padding: 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background 0.3s;
  }
  
  .login-container button:hover {
    background: #0056b3;
  }
  
  /* Error message styling */
  .login-container .error {
    color: #ff4d4d;
    font-size: 14px;
    background: #ffe5e5;
    padding: 10px;
    border: 1px solid #ff4d4d;
    border-radius: 4px;
    margin-bottom: 15px;
    text-align: left;
  }