/* app/dashboard/react_components/src/components/NavBar/NavBar.css */

.navbar {
  background-color: #333;
  color: #fff;
  padding: 10px 20px; /* Add some horizontal padding */
  display: flex;
  justify-content: space-between;
  align-items: center; /* Align items vertically */
  position: sticky;
  top: 0;
  z-index: 1000; /* Ensure it stays above other elements */
  height: 30px; /* Fixed height for consistent spacing */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.navbar ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.navbar li {
  margin: 0 10px;
}

.navbar a {
  color: #fff;
  text-decoration: none;
}

.navbar .logout-button {
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
}